@import "../../../theme/mixins.scss";

.loading {
  margin: 50px 0px 30px;
}

.block {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.banner {
  width: 100%;
  background-color: var(--white);
}

.imageContainer {
  position: relative;
  padding-bottom: 50%;
  margin-bottom: 3rem;
  overflow: hidden;

  @media (min-width: 1200px) {
    padding-bottom: 44rem;
  }

  @include responsive(phone) {
    padding-bottom: 90%;
    margin-bottom: 18px;
  }

  &.notBanner {
    padding-bottom: 19.6rem;
    margin-bottom: 0px;

    @include responsive(phone) {
      padding-bottom: 178px;
    }

    & .btnBlock {
      bottom: 0px;
    }

    & button {
      color: var(--secondary-50);

      &:hover {
        color: var(--secondary);

        &::before {
          background-color: var(--bg-gray);
        }

        & > .arrow {
          &::before,
          &::after {
            background-color: var(--secondary);
          }
        }
      }
      &::before {
        position: absolute;
        z-index: -1;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: var(--bg-gray);
      }

      & > .arrow {
        &::before,
        &::after {
          position: absolute;
          content: "";
          left: 2rem;
          top: 48%;
          width: 1rem;
          height: 0.22rem;
          transform: translateY(0.1rem) rotate(-45deg);
          transform-origin: left;
          background-color: var(--secondary);
          transition: all 0.15s ease-in-out;
        }
        &::after {
          transform: translateY(-0.05rem) rotate(45deg);
        }
      }
    }
  }

  & .btnBlock {
    position: absolute;
    z-index: 1;
    bottom: 4.2rem;
  }
  & button {
    position: relative;
    padding: 1.6rem 2rem 1.6rem 3.8rem;
    color: var(--primary-50);
    font-size: 1.4rem;
    font-weight: 600;
    border: none;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: var(--primary);

      &::before {
        background-color: rgba(246, 246, 249, 0.4);
      }

      & > .arrow {
        &::before,
        &::after {
          background-color: var(--primary);
        }
      }
    }
    &::before {
      position: absolute;
      z-index: -1;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: var(--primary-20);
      border-radius: 4px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      transition: all 0.15s ease-in-out;
    }

    & > .arrow {
      &::before,
      &::after {
        position: absolute;
        content: "";
        left: 2rem;
        top: 48%;
        width: 1rem;
        height: 0.22rem;
        transform: translateY(1px) rotate(-45deg);
        transform-origin: left;
        background-color: var(--primary);
        transition: all 0.15s ease-in-out;
      }
      &::after {
        transform: translateY(-0.05rem) rotate(45deg);
      }
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
  color: var(--secondary-70);
}

.header {
  padding: 30px 0px 28px;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.07);

  & > .title {
    font-size: 4.6rem;
    line-height: 5.35rem;
    font-weight: 600;
    max-width: 75%;
    color: var(--secondary);

    @include responsive(desktop) {
      font-size: 45px;
      line-height: 48.8px;
    }
    @include responsive(tablet) {
      font-size: 40px;
      line-height: 46px;
      max-width: 80%;
    }
    @include responsive(averagePhone) {
      font-size: 32px;
      line-height: 36.8px;
      max-width: 100%;
    }
  }
  & > .date {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.frame {
  width: 100%;
  height: 100%;

  @include responsive(averagePhone) {
    display: none;
  }
}

.finReportMob {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #202124;
  border-radius: 5px;

  @include responsive(averagePhone) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contentMob {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 200px;

  & > h3 {
    color: #8f9194;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & > a {
    padding: 10px 15px;
    color: var(--secondary);
    background-color: #89b6f2;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
  }
}
