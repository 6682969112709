@import "../../../../theme/mixins.scss";

.card {
    position: relative;
    width: 100%;
    height: 36.3rem;
    padding-bottom: 100.5%;
    display: flex;
    cursor: pointer;
    border-radius: 16px;
    overflow: hidden;

    &:hover {
        .img {
            transform: scale(1.1);
            filter: brightness(0.7);
        }
    }
}

.img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s linear, filter 0.2s linear;
}

.soon {
    position: absolute;
    z-index: 2;
    top: 3.2rem;
    left: 3.2rem;
    padding: 0.9rem 1rem;
    color: var(--white);
    border-radius: 4px;
    background: #f6f6f91c;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.content {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1.2rem;
    font-weight: 600;
}

.content img {
    width: 1.4rem;
}

.arrow {
    position: absolute;
    z-index: 2;
    bottom: 4rem;
    right: 3rem;
}

.title {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    left: 30px;
    max-width: 21rem;
    color: var(--white);
    font-size: 3rem;
    line-height: 3.2rem;
    font-weight: 600;
}

.gradient {
    background: linear-gradient(0deg, black -3rem, rgba(8, 14, 31, 0));
    bottom: -1px;
    left: -1px;
    position: absolute;
    right: -1px;
    top: 20%;
    transition: top 0.2s;
    will-change: top;
    z-index: 1;
}
