@import "../../../../../../theme/mixins.scss";

.card {
  position: relative;
  width: 100%;
  height: 363px;
  padding-top: 100.5%;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #000000 -4rem, rgba(8, 14, 31, 0) 24%);
  }

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(0deg, #000000e6 3rem, rgba(11, 11, 12, 0) 50%);
  }

  &:nth-child(3) {
    & > .title {
      max-width: 18.5rem;
    }
  }

  @media (min-width: 840px) {
    &:hover {
      .img {
        transform: scale(1.1);
        filter: brightness(0.8);
      }
      .ex {
        opacity: 50%;
      }
    }
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition:
    transform 0.2s linear,
    filter 0.2s linear;
}

.ex {
  font-size: 3rem;
  color: var(--white);
  font-weight: 600;
  opacity: 20%;
  position: absolute;
  z-index: 3;
  top: 3rem;
  left: 3rem;
  transition: opacity 0.2s linear;

  @include responsive(averageTablet) {
    opacity: 50%;
  }
}

.soon {
  position: absolute;
  z-index: 3;
  top: 3.5rem;
  right: 3rem;
  padding: 0.9rem 1rem;
  color: var(--white);
  border-radius: 4px;
  background: #f6f6f91c;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

.content {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.2rem;
  font-weight: 600;
}

.content img {
  width: 1.4rem;
}

.arrow {
  position: absolute;
  z-index: 3;
  bottom: 4rem;
  right: 3rem;
}

.title {
  color: var(--white);
  font-size: 2.8rem;
  line-height: 2.8rem;
  font-weight: 600;
  position: absolute;
  z-index: 3;
  bottom: 3.5rem;
  left: 3rem;
  max-width: 19.6rem;

  @include responsive(averageDesktop) {
    font-size: 30px;
    line-height: 30px;
  }
}
