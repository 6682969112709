@import "../../../../../../theme/mixins.scss";

.slide {
    cursor: pointer;
    user-select: none;

    &::before {
        position: absolute;
        content: "";
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
    }
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.bottomBlock {
    color: var(--white);
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    padding-bottom: 79px;
    background: linear-gradient(0deg, rgba(11, 11, 12, 0.85) 0%, rgba(11, 11, 12, 0) 100%);

    @include responsive(averagePhone) {
        padding-bottom: 96px;
    }
}

.innerContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.number {
    position: relative;
    font-weight: 600;
    font-size: 8rem;
    line-height: 10.056rem;
    background: linear-gradient(180deg, #9b9a9a 0%, rgba(246, 246, 249, 0.3) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;

    @include responsive(desktop) {
        font-size: 5.6rem;
        line-height: 7.039rem;
    }
}

.description {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 4.022rem;
    max-width: 59.6rem;

    @include responsive(desktop) {
        font-size: 2.4rem;
        line-height: 3.017rem;
    }
}

// loader
.loaderWrapper {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 24px;
}

.btn {
    cursor: pointer;
    border-radius: 4px;
    padding: 1.6rem 2rem;
    background: rgba(246, 246, 249, 0.1);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    transition: background-color 0.2s linear;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover {
        background-color: var(--black);
    }
}

.arrowIcon {
    width: 1.1rem;
    pointer-events: none;
}

.innerLoader {
    display: flex;
    flex-direction: column;
    width: 16rem;
    row-gap: 1px;

    p {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.508rem;
        color: var(--primary-50);
    }
    span {
        font-size: 1.4rem;
        line-height: 1.76rem;
    }
}

.loader {
    width: 100%;
    margin-top: 8px;
    height: 0.2rem;
    background-color: rgb(151, 151, 151);
    position: relative;
    overflow: hidden;
}

.loaderBar {
    height: 100%;
    background-color: var(--white);
    width: 0%;
    opacity: 1 !important;
    z-index: 22;
    position: absolute;
    transition: width 0.1s ease-in-out;
}
// loader
