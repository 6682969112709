@import "../../../../../../theme/mixins.scss";

.parent {
  position: relative;
}

.cardWrapper {
  position: absolute;
  z-index: 21;
  top: 0;
  left: 0;
  color: var(--white);
  background: var(--bg-lightBlack);
  width: 100%;
  border-radius: 16px;
  padding: 4.8rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @include responsive(desktop) {
    flex-direction: column;
  }

  @include responsive(averagePhone) {
    padding: 32px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4.4rem;

  @include responsive(averagePhone) {
    gap: 29px;
  }
}

.quote {
  width: 4.6rem;
}

.text {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.76rem;
  max-width: 53.9rem;

  @include responsive(desktop) {
    max-width: 100%;
  }

  @include responsive(averagePhone) {
    font-size: 15px;
    line-height: 17.25px;
  }
}

.nameBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include responsive(desktop) {
    position: absolute;
    bottom: 48px;
  }

  @include responsive(averagePhone) {
    bottom: 32px;
    width: 40%;
  }

  & > .name {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.011rem;
    color: var(--bg-btn);
  }

  & > .position {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.508rem;
    color: var(--primary-70);
  }
}

.imageBlock {
  margin: -4.8rem;
  width: 48.8rem;

  @include responsive(desktop) {
    align-self: flex-end;
    width: 70%;
    margin-top: 5px;
  }

  @include responsive(averagePhone) {
    margin: 5px -32px -32px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
