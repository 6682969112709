@import "../../../theme/mixins.scss";

.privacy {
    background-color: var(--white);
    color: var(--primary_main);
    padding-top: var(--pt-190);

    @include responsive(phone) {
        padding-top: var(--pt-136);
    }
}

.header {
    position: relative;
    text-align: center;
    z-index: 1;
}

.title {
    font-size: 50px;
    font-weight: 600;
    line-height: 57.5px;
    margin-bottom: 12px;

    @include responsive(averageTablet) {
        font-size: 45px;
        line-height: 48.5px;
    }
    @include responsive(phone) {
        font-size: 32px;
        line-height: 36px;
    }
}
