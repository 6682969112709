@import "../../theme/mixins.scss";

.title {
  font-size: 40px;
  font-weight: 600;
  color: #0b0b0c;
  max-width: 290px;
  padding: 0 0 12px;
  line-height: 40px;
}

.desc {
  font-size: 14px;
  color: #0b0b0c;
  opacity: 70%;
  font-weight: 400;
  max-width: 192px;
  padding-bottom: 32px;
  line-height: 18px;
}

.textBlock {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal {
  :global {
    .ant-modal-content {
      width: 622px;
      overflow: auto;
      background-color: #f6f6f9;
      padding: 0;
      border-radius: 16px;
      @include responsive(phone) {
        width: 100%;
      }
      .ant-modal-close {
        top: 18px;

        &:hover {
          transform: scale(1.2);
        }

        .ant-modal-close-x {
          font-size: 22px;
          color: #000000;
        }
      }
    }
    .ant-modal-header {
      border-radius: 16px !important;
      text-align: center;
      height: 80px;
      // background: var(--grey, linear-gradient(261deg, #4e4e4e 4.9%, #b1b1b1 82.8%)) !important;
      background: #f6f6f9 !important;
      border-radius: 4px;
      padding: 32px 24px 10px;
      color: white !important;
    }
    .ant-divider {
      margin: 15px 0px 30px -24px !important;
      border: 0.7px solid #333155;
      width: 558px;
    }
    .ant-modal-body {
      padding: 56px 16px 0px;
      @include responsive(phone) {
        padding: 50px 14px 0px;
      }
    }

    .ant-modal-footer {
      padding: 0px;
    }

    .ant-modal-title {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      color: white;
    }
  }
}

@media (max-width: 700px) {
  .modal {
    :global {
      .ant-modal-content {
        width: 100%;
      }
    }
  }
}

.subheader {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.divider {
  :global {
    .ant-divider {
      .ant-divider-horizontal {
        margin: 0 !important;
      }
    }
  }
}
