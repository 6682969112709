.language{
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.list {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.blockLangActive {
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 4px;
  background: #f6f6f91c;
  padding: 0.8rem;
}
.blockLang {
  opacity: 50%;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 4px;
}

.lang {
  font-size: 1.2rem;
  opacity: 0, 5;
}
