@import "../../../../theme/mixins.scss";

.ourProjects {
  background-color: var(--black);
  padding: 100px 0px 18rem;

  @include responsive(desktop) {
    padding: 100px 0px 35rem;
  }

  @include responsive(averagePhone) {
    padding: 70px 0px 180px;
  }
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 28px;
  margin-bottom: 72px;

  @include responsive(desktop) {
    align-items: start;
    flex-direction: column;
  }

  @include responsive(averagePhone) {
    margin-bottom: 40px;
  }

  h2 {
    max-width: 56.5rem;
    font-size: 4.6rem;
    font-weight: 600;
    line-height: 5.35rem;
    color: var(--white);

    @include responsive(tablet) {
      font-size: 4rem;
      line-height: 4.6rem;
    }
  }

  p {
    color: var(--primary-70);
    max-width: 38.4rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.76rem;
    margin-right: 45px;
    margin-bottom: 6px;

    @include responsive(desktop) {
      margin-bottom: 0px;
    }
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 110px;

  @include responsive(averageDesktop) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive(averagePhone) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 80px;
  }
}
