@import "../../../../theme/mixins.scss";

.block {
  padding: 24px 10px;
  color: var(--white);
  border-radius: 16px;
  background: var(--bg-gray);

  @include responsive(averagePhone) {
    padding: 20px 10px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 8px 0px;
  padding-left: 24px;
  gap: 10px;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  border-radius: 30px;
  background: var(--white);
}

.bigTitle {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.76rem;
  color: var(--black);
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.76rem;
  color: var(--secondary-70);
}
