@import "../../theme/mixins.scss";

// swiper
.swiper {
  display: grid;
  position: relative;
  transition: all 0.2s ease;

  &.pb {
    padding-bottom: 60px;

    @include responsive(tablet) {
      padding-bottom: 40px;
    }
  }

  &.dark {
    :global {
      .swiper-pagination {
        color: #c6c6c6;

        & > span > span {
          color: var(--white);
        }
      }
    }
  }

  .image {
    position: relative;
    padding-top: 55.5%;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      user-select: none;
      width: 100%;
      height: 100%;
      filter: blur(3px);
      -webkit-filter: blur(3px);
      -moz-filter: blur(3px);
      transform: scale(1.04);
    }
  }

  :global {
    .swiper-slide {
      overflow: hidden;
      pointer-events: none;
      // max-height: 80vh;
    }
    .swiper-pagination {
      bottom: var(--swiper-pagination-bottom, 0px);
      color: #727272;
      font-weight: 400;
      font-size: 16px;
      width: 31%;

      & > span > span {
        font-size: 4rem;
        font-weight: 800;
        color: #222;

        @include responsive(tablet) {
          font-size: 28px;
        }
      }

      @include responsive(tablet) {
        font-size: 14px;
        bottom: var(--swiper-pagination-bottom, 0px);
      }
    }
    & :is(.swiper-button-prev, .swiper-button-next) {
      color: #222;
      font-weight: 800;
      top: 0;
      bottom: 0;
      height: 100%;
      width: calc(var(--swiper-navigation-size) / 44 * 67);
      transition: color 0.15s ease-in-out;
      -webkit-tap-highlight-color:  transparent;

      &:hover {
        color: #cf122d !important;
      }

      @include responsive(tablet) {
        width: calc(var(--swiper-navigation-size) / 44 * 40);

        &::after {
          font-size: 3.4rem;
        }
      }
      @include responsive(phone) {
        &::after {
          font-size: 2.4rem;
        }
      }
    }
    .swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 0px);
    }
    .swiper-button-prev {
      left: var(--swiper-navigation-sides-offset, 0px);
    }

    @media (min-width: 1025px) {
      .swiper-slide {
        border: 1px solid rgba($color: #fff, $alpha: 0.2);
        // max-height: 80vh;

        &-prev {
          flex: 0.25;
        }
        &-active {
          flex: 0.5;
        }
        &-next {
          flex: 0.25;
        }
      }

      .swiper-wrapper {
        &:not(:has(.swiper-slide-prev)) {
          .swiper-slide {
            &-active {
              flex: 0.75;
              transform: translateX(-17%);
            }
            &-next {
              flex: 0.25;
              transform: translateX(-50%);
            }
          }
        }
        &:not(:has(.swiper-slide-next)) {
          .swiper-slide {
            &-prev {
              flex: 0.25;
              transform: translateX(48%);
            }
            &-active {
              flex: 0.75;
              transform: translateX(16%);
            }
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .swiper-pagination {
        text-align: left;
        padding-left: 15px;
      }
    }
  }
}
// swiper
