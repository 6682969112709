@import "../../../theme/mixins.scss";

.loading {
    margin: 50px 0px 30px;
}

.block {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.banner {
    width: 100%;
    background-color: var(--white);
}

.imageContainer {
    position: relative;
    padding-bottom: 50%;
    margin-bottom: 3rem;
    overflow: hidden;

    @media (min-width: 1200px) {
        padding-bottom: 44rem;
    }

    @include responsive(phone) {
        padding-bottom: 90%;
        margin-bottom: 0px;
    }

    &.notBanner {
        padding-bottom: 19.6rem;
        margin-bottom: 0px;

        @include responsive(phone) {
            padding-bottom: 178px;
        }

        & .btnBlock {
            bottom: 0px;
        }

        & button {
            color: var(--secondary-50);

            &:hover {
                color: var(--secondary);

                &::before {
                    background-color: var(--bg-gray);
                }

                & > .arrow {
                    &::before,
                    &::after {
                        background-color: var(--secondary);
                    }
                }
            }
            &::before {
                position: absolute;
                z-index: -1;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                background-color: var(--bg-gray);
            }

            & > .arrow {
                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    left: 2rem;
                    top: 48%;
                    width: 1rem;
                    height: 0.22rem;
                    transform: translateY(0.1rem) rotate(-45deg);
                    transform-origin: left;
                    background-color: var(--secondary);
                    transition: all 0.15s ease-in-out;
                }
                &::after {
                    transform: translateY(-0.05rem) rotate(45deg);
                }
            }
        }
    }

    & .btnBlock {
        position: absolute;
        z-index: 1;
        bottom: 4.2rem;
    }
    & button {
        position: relative;
        padding: 1.6rem 2rem 1.6rem 3.8rem;
        color: var(--primary-50);
        font-size: 1.4rem;
        font-weight: 600;
        border: none;
        transition: all 0.15s ease-in-out;

        &:hover {
            color: var(--primary);

            &::before {
                background-color: rgba(246, 246, 249, 0.4);
            }

            & > .arrow {
                &::before,
                &::after {
                    background-color: var(--primary);
                }
            }
        }
        &::before {
            position: absolute;
            z-index: -1;
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: var(--primary-20);
            border-radius: 4px;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            transition: all 0.15s ease-in-out;
        }

        & > .arrow {
            &::before,
            &::after {
                position: absolute;
                content: "";
                left: 2rem;
                top: 48%;
                width: 1rem;
                height: 0.22rem;
                transform: translateY(1px) rotate(-45deg);
                transform-origin: left;
                background-color: var(--primary);
                transition: all 0.15s ease-in-out;
            }
            &::after {
                transform: translateY(-0.05rem) rotate(45deg);
            }
        }
    }
}

.imageBlock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;

    @include responsive(phone) {
        object-fit: cover;
    }
}

.imageBack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    transform: scale(1.05);
}

.wrapper {
    position: relative;
    width: 100%;
    color: var(--secondary-70);
}

.header {
    padding: 30px 0px 28px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.07);

    & > .title {
        font-size: 4.6rem;
        line-height: 5.35rem;
        font-weight: 600;
        max-width: 75%;
        color: var(--secondary);

        @include responsive(desktop) {
            font-size: 45px;
            line-height: 48.8px;
        }
        @include responsive(tablet) {
            font-size: 40px;
            line-height: 46px;
            max-width: 80%;
        }
        @include responsive(averagePhone) {
            font-size: 28px;
            line-height: 32.8px;
            max-width: 100%;
        }
    }
    & > .date {
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.content {
    color: var(--secondary-70);
    padding: 24px 0px;
    max-width: 70%;

    @include responsive(desktop) {
        max-width: 100%;
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4 {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 600;
        color: var(--secondary);

        @include responsive(phone) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }

    font-size: 1.4rem;
    line-height: 1.76rem;
    font-weight: 400;

    p {
        font-size: 1.4rem;
        line-height: 1.76rem;
        font-weight: 400;
    }

    & > h1 + p,
    & > h2 + p,
    & > h3 + p,
    & > h4 + p {
        margin-top: 10px;

        @include responsive(phone) {
            margin-top: 5px;
        }
    }

    & ul,
    & ol {
        margin: 0 auto;
        padding: 0 19px;
        font-size: 1.4rem;
        font-weight: 400;

        & > li {
            list-style-type: disc;
            margin: 12px 0 12px 16px;
        }
    }
}

// video
.mobile {
    @include responsive(phone) {
      display: none;
    }
}

.videoContent {
    max-width: 808px;
    margin: 0 auto;
}

.videoBlock {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    margin-top: 15px;
}

.videoPlayer {
    max-height: calc(100vh - 200px);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);
    pointer-events: all;
}

.video {
    border: 0px;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// video