html,
body {
  background: var(--background-color);
  scroll-behavior: smooth;
  /* background: var(--white); */
  color: rgba(0, 0, 0, 1);
  overflow-x: hidden;
  font-size: var(--font-size);

  @media (min-width: 2000px) {
    font-size: calc(0.625rem + 4.300 * ((100vw - 375px) / 1545));
  }
}

/* 
html:has(.ant-image-preview-mask, .ant-modal-mask) {
  overflow-x: inherit;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  background: none;
}

textarea {
  resize: none;
}

ul,
li {
  list-style: none;
}

.wrapperApp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainApp {
  flex: 1;
}

/* Стандартный стиль скроллбара */
::-webkit-scrollbar {
  width: 6px;
}

/* Ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background: rgba(11, 11, 12, 0.5);
  border-radius: 6px;
}

/* Ползунок скроллбара при наведении */
::-webkit-scrollbar-thumb:hover {
  background: rgba(11, 11, 12, 0.7);
}

/* Полоса скроллбара */
::-webkit-scrollbar-track {
  background: rgba(246, 246, 249, 0.5);
  border-radius: 6px;
}

/* Полоса скроллбара при наведении */
::-webkit-scrollbar-track:hover {
  background: rgba(246, 246, 249, 0.6);
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: url("./fonts/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

.viewport {
  overflow: hidden;  /* Ensures no scrollbars appear */
  position: relative; /* Aligns children within this container */
  margin: 0;
  padding: 0;
}
.viewport > * {
  margin-bottom: 0;  /* Removes bottom margin from all direct children */
}
