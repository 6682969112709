@import "../../../../../theme/mixins.scss";

.form {
  text-align: center;

  :global {
    .special-label {
      display: none;
    }

    .ant-form-item.ant-form-item-has-error .form-control {
      border: 1px solid #ff4d4f;
      background-color: white;
      transition: border-color 0.1s linear;

      &:hover {
        border-color: rgba(255, 77, 79, 0.5);
      }
    }
  }
}

.form :global(.ant-form-item) {
  margin-bottom: 16px !important;
}

.phone {
  width: 100%;
  background-color: #ebebf2;
  color: rgba(0, 0, 0, 0.88);
  border-color: transparent;
  border-radius: 16px;
  padding: 4px 20px;
  height: 64px;
  font-size: 14.5px;
  transition: all 0.1s linear;

  &::placeholder {
    color: rgba(0, 0, 0, 0.58) !important;
    font-weight: 600;
  }

  &:focus {
    background-color: white;
    outline: none;
    border: 2px solid rgba(135, 206, 235, 0.3);
  }

  &:hover {
    background-color: white;
  }
}

.btn {
  flex: 0.1;
  background-color: var(--bg-btn);
  font-size: 14px;
  font-weight: 600;
  height: 64px;
  width: 100%;
  line-height: 17.6px;
  border: none !important;
  color: var(--white) !important;
  border-radius: 16px !important;

  @include responsive(averageTablet) {
    width: 100%;
  }

  &:hover {
    background-color: var(--bg-btn-hover);
  }
  &:active {
    background-color: var(--bg-btn-click);
  }
}

.cover {
  min-height: 120px !important;
  padding: 20px;
}
