@import "../../../theme/mixins.scss";

.contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding-top: 160px;

    @include responsive(averageTablet) {
        align-items: baseline;
        flex-direction: column;
    }
    @include responsive(tablet) {
        padding-top: var(--pt-136);
        gap: 40px;
    }
}

.content {
    flex: 0.5;
    display: flex;
    flex-direction: column;

    & > .title {
        font-size: 4.6rem;
        font-weight: 600;
        line-height: 5.35rem;
        margin-bottom: 85px;
        color: var(--white);

        @include responsive(tablet) {
            font-size: 4rem;
            line-height: 4.6rem;
            margin-bottom: 32px;
        }
    }

    & > .number {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.51rem;
        margin-bottom: 23px;
        color: var(--white);

        @media (min-width: 540px) {
            pointer-events: none;
        }
    }

    & > .email,
    & > .address {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.508rem;
        color: var(--white);
    }

    & > .address {
        color: var(--primary-70);
        margin: 8px 0px;
        font-style: normal;
    }

    & > .socials {
        display: flex;
        gap: 10px;
        margin: 8px 0px;

        & > a > img {
            width: 2.4rem;
        }
    }
}

.mapBlock {
    flex: 1;
    width: 100%;
    height: 60vh;

    @include responsive(averageDesktop) {
        height: 400px;
    }
    @include responsive(averagePhone) {
        height: 250px;
    }
}

.map {
    flex: 1;
    position: relative;
    width: 99%;
    height: 60vh;
    border-radius: 16px;
    overflow: hidden;

    @include responsive(averageDesktop) {
        height: 400px;
    }
    @include responsive(averageTablet) {
        width: 100%;
    }
    @include responsive(averagePhone) {
        height: 250px;
    }
}
