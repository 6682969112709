.card {
  position: relative;
  width: 100%;
  height: 36.3rem;
  padding-bottom: 100.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  background-color: rgb(44 44 44 / 86%);
  overflow: hidden;
}

.content {
  position: absolute;
  top: calc(50% - 50px);
  text-align: center;
}

.title {
  color: var(--white);
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 600;
}

.date {
  margin-top: 1rem;
  color: var(--white);
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 600;
}
