@import "../../../theme/mixins.scss";

// loader
.loadingWrapper {
  height: 50vh;
  background-color: var(--white);
}
// loader

.wrapper {
  padding-top: 146px;
  position: relative;
  width: 100%;
  color: var(--secondary-70);
}

.soon {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  padding: 1.6rem 2rem;
  color: var(--black);
  border-radius: 16px;
  background-color: var(--bg-gray);
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    & > p {
      color: var(--secondary-70);
    }
  }

  & > p {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.76rem;
    color: var(--secondary-50);
    transition: color 0.1s linear;
  }
}

.header {
  padding-bottom: 55px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 60px;
  border-bottom: 1px solid var(--border-7-dark);

  @include responsive(averageTablet) {
    & > .btn {
      display: none !important;
    }
  }

  @include responsive(averagePhone) {
    padding-bottom: 40px;
    border-bottom: none;
  }

  & > .title {
    font-size: 4rem;
    line-height: 4.6rem;
    font-weight: 600;
    max-width: 75%;
    color: var(--secondary);

    @include responsive(tablet) {
      max-width: 80%;
    }

    @include responsive(averagePhone) {
      font-size: 32px;
      line-height: 36.8px;
      max-width: 100%;
    }
  }

  & > .btn {
    display: block;
  }
}

.btn {
  display: none;
  background-color: var(--bg-btn);
  font-size: 1.4rem;
  font-weight: 600;
  width: 28.9rem;
  height: 6.4rem;
  line-height: 1.76rem;
  border: none !important;
  color: var(--white) !important;
  border-radius: 16px !important;
  padding: 2.2rem 3.2rem !important;

  @media (min-width: 2000px) {
    padding: 2.1rem 2.6rem !important;
  }

  @include responsive(averageTablet) {
    display: block;
    width: 100%;
    margin-bottom: 56px;
  }

  &:hover {
    background-color: var(--bg-btn-hover);
  }
  &:active {
    background-color: var(--bg-btn-click);
  }

  &.clicked {
    background-color: var(--white) !important;
    color: var(--bg-btn-hover) !important;
    border: 1px solid var(--bg-btn-click) !important;
  }
}

.infoBlock {
  padding: 56px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @include responsive(desktop) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive(averagePhone) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 0px 40px;
  }
}

.content {
  color: var(--secondary-70);
  padding: 40px 0px 56px;
  border-top: 1px solid var(--border-7-dark);

  &:last-child {
    padding-bottom: 0px;
  }

  @include responsive(tablet) {
    &:last-child {
      padding-bottom: 8px;
    }
  }

  & > .text {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.76rem;
    max-width: 30rem;
    margin-bottom: 24px;
    color: var(--secondary);
  }

  & > .desc {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.76rem;
    color: var(--secondary-70);
    max-width: 70%;

    @include responsive(tablet) {
      max-width: 75%;
    }

    @include responsive(averagePhone) {
      max-width: 100%;
    }
  }
}
