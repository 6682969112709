@import "../../../../../../theme/mixins.scss";

.card {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 363px;
    padding-top: 100.5%;
    background-color: var(--black);
    color: var(--white);
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;

    &:nth-child(1) {
        &:hover {
            & > .number {
                color: #ec1d23;
                transition: all 0.3s linear;
            }
            & > img {
                filter: grayscale(0%);
                transform: scale(1.1) rotate(-5deg);
            }
        }

        @include responsive(averagePhone) {
            & > .number {
                color: #ec1d23 !important;
            }
        }
    }
    &:nth-child(2) {
        &:hover {
            & > .number {
                color: var(--bg-btn);
                transition: all 0.3s linear;
            }
            & > img {
                filter: grayscale(0%);
                transform: scale(1.1) rotate(-5deg);
            }
        }

        & > img {
            width: 68%;
        }

        @include responsive(averagePhone) {
            & > .number {
                color: var(--bg-btn) !important;
            }
        }
    }
    &:nth-child(3) {
        &:hover {
            & > .number {
                color: #75bb4b;
                transition: all 0.3s linear;
            }
            & > img {
                filter: grayscale(0%);
                transform: scale(1.1) rotate(-5deg);
            }
        }

        & > img {
            width: 63%;
        }

        @include responsive(averagePhone) {
            & > .number {
                color: #75bb4b !important;
            }
        }
    }

    & > .number {
        position: absolute;
        top: 32px;
        left: 32px;
        color: var(--primary-20);
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 2.88rem;
        transition: all 0.5s linear;
    }

    & > img {
        position: absolute;
        top: 0;
        right: 0;
        width: 48%;
        filter: grayscale(100%);
        transition: all 0.3s linear;

        @include responsive(averagePhone) {
            filter: grayscale(0%);
        }
    }

    & > .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 32px 32px 40px;

        & > .title {
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 600;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: 19.5rem;

            &.ky {
                max-width: 26.5rem;
            }


            @include responsive(averagePhone) {
                font-size: 32px;
                line-height: 32px;

                &.en {
                    line-height: 35px;
                    max-width: 20.5rem;
                }
            }
        }

        & > .desc {
            font-size: 1.4rem;
            line-height: 1.76rem;
            color: var(--primary-70);
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: 22rem;

            @include responsive(averagePhone) {
                font-size: 14px;
                line-height: 17.6px;
            }
        }
    }
}
