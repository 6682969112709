.input {
  background-color: #EBEBF2;
  border: none;
  border-radius: 16px;
  padding: 4px 20px;
  height: 64px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.58) !important;
    font-weight: 600;
  }
}

.input :global(.ant-input) {
  color: #0B0B0C;
  background-color: #EBEBF2;
}

.input :global(.ant-input::placeholder) {
  color: #0B0B0C;
  opacity: 50%;
}
