@import "../../../theme/mixins";

.title {
    font-size: 4.6rem;
    font-weight: 600;
    line-height: 5.35rem;
    padding: var(--pt-190) 0px 70px;
    color: var(--black);

    @include responsive(desktop) {
        font-size: 45px;
        line-height: 48.8px;
        padding-bottom: 48px;
    }
    @include responsive(tablet) {
        padding-top: var(--pt-136);
        font-size: 4rem;
        line-height: 4.6rem;
    }
}

.newsList {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include responsive(desktop) {
        gap: 15px;
    }
    @include responsive(tablet) {
        gap: 10px;
    }
}

.newsGroup {
    display: flex;
    gap: 20px;

    @include responsive(desktop) {
        gap: 15px;
    }
    @include responsive(tablet) {
        gap: 10px;
    }
    @include responsive(averagePhone) {
        flex-direction: column;
    }
}

.bigNews {
    flex: 1;
}

.smallNews {
    flex: 0.488;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include responsive(hugeDesktop) {
        flex: 0.484;
    }
    @include responsive(desktop) {
        gap: 15px;
    }
    @include responsive(tablet) {
        gap: 10px;
    }

    @media (min-width: 2000px) {
        flex: 0.491;
    }

    & .header {
        font-size: 2rem !important;
        max-height: 5rem !important;
        width: 80% !important;

        @include responsive(desktop) {
            font-size: 18px !important;
            max-height: 46px !important;
        }
    }

    & .desc {
        font-size: 1.2rem !important;
        -webkit-line-clamp: 2 !important;

        @include responsive(desktop) {
            font-size: 10px !important;
        }
        @include responsive(averageTablet) {
            font-size: 12px !important;
        }
    }

    & .arrow {
        &::before,
        &::after {
            bottom: 4.6rem !important;

            @include responsive(desktop) {
                bottom: 36px !important;
            }
            @include responsive(averagePhone) {
                bottom: 35.5px !important;
            }
        }
    }
}

.empty {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;

    @include responsive(averagePhone) {
        height: 10vh;
    }
}

.loadBtn {
    height: 6.4rem;
    width: 100%;
    background-color: var(--bg-gray);
    color: var(--secondary-50);
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 16px;
    margin: 40px 0px;
    border: none;
    overflow: hidden;
    transition: color 0.15s linear;

    &:not(:disabled):hover {
        color: var(--secondary-70) !important;
    }

    @include responsive(tablet) {
        margin-bottom: 8px;
    }
}

// skeleton
.skeleton {
    position: relative;
    display: block;
    padding-top: 70.5%;
    border-radius: 16px;
    border: 1px solid rgb(201 201 201 / 50%);
    background-color: rgb(201 201 201 / 20%);
    overflow: hidden;

    & > .content {
        position: absolute;
        bottom: 0;
        padding: 40px 32px;

        @include responsive(desktop) {
            padding: 30px;
        }

        :global {
            .ant-skeleton-title {
                @include responsive(desktop) {
                    height: 12px;
                }
                @include responsive(tablet) {
                    height: 10px;
                }
            }
            .ant-skeleton-paragraph {
                @include responsive(desktop) {
                    & > li {
                        height: 12px;
                    }
                }
                @include responsive(tablet) {
                    margin-block-start: 0px !important;

                    & > li {
                        height: 10px;
                        margin-block-start: 12px !important;
                    }
                }
            }
        }
    }
}
// skeleton

// card
.card {
    position: relative;
    display: block;
    padding-top: 70.5%;
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;

    @media (min-width: 840px) {
        &:hover {
            .image {
                transform: scale(1.2);
            }

            .content {
                & > .desc {
                    opacity: 1;
                    max-height: 10rem;
                    margin-top: 12px;
                    transition: opacity 0.6s 0.1s, max-height 0.6s,
                        margin-top 0.3s;
                }
            }

            .arrow {
                &::before,
                &::after {
                    background-color: var(--white);
                }
            }
        }
    }

    & > .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: var(--secondary);
        transition: transform 0.3s ease-in-out;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & > .content {
        position: absolute;
        bottom: 0px;
        padding: 4rem 3.2rem;
        left: 0px;
        right: 0px;
        color: var(--white);
        z-index: 1;

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            top: -100%;
            left: 0px;
            right: 0px;
            z-index: -1;
            background: linear-gradient(
                0deg,
                rgba(11, 11, 12, 1) 0%,
                rgba(11, 11, 12, 0) 100%
            );
            will-change: top;
            transition: top 0.2s;
        }

        @include responsive(desktop) {
            padding: 3rem;
        }

        & > .date {
            color: var(--primary-70);
            font-size: 1.2rem;
            font-weight: 600;
        }
        & > .header {
            font-size: 3.2rem;
            font-weight: 600;
            width: 70%;
            max-height: 8rem;
            overflow: hidden;

            @include responsive(desktop) {
                font-size: 28px;
                max-height: 70px;
            }
            @include responsive(tablet) {
                font-size: 24px;
                max-height: 60px;
            }
            @include responsive(averagePhone) {
                font-size: 1.8rem;
                max-height: 47px;
                width: 80%;
            }
        }
        & > .desc {
            font-size: 1.4rem;
            color: var(--primary-70);
            font-weight: 400;
            width: 80%;
            opacity: 0;
            max-height: 0px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            transition: opacity 0.3s 0s, max-height 0.3s, margin-top 0.3s;

            @include responsive(desktop) {
                font-size: 12px;
            }
        }
    }

    .arrow {
        &::before,
        &::after {
            position: absolute;
            content: "";
            right: 4rem;
            bottom: 5.3rem;
            width: 1rem;
            height: 0.2rem;
            z-index: 1;
            background-color: var(--primary-70);
            transform: translateY(-0.7rem) rotate(45deg);
            transition: background-color 0.5s;

            @include responsive(desktop) {
                bottom: 41px;
            }
            @include responsive(averagePhone) {
                bottom: 35.5px;
                background-color: var(--white);
            }
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}
// card
