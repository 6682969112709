@import "../../../../theme/mixins.scss";

.block {
    padding: 24px;
    color: var(--white);
    border-radius: 16px;
    background: var(--bg-lightBlack);
}

.content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 35px;

    @include responsive(averagePhone) {
        gap: 25px;
    }
}

.bigTitle {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.07rem;
    color: var(--primary-50);
}

.text {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.61rem;
    color: var(--white);
    max-width: 14.8rem;
    display: flex;
    align-items: flex-end;
    height: 3.6rem;
}
