@import "../../../theme/mixins.scss";

.vacancies {
  display: flex;
  flex-direction: column;
  gap: 110px;

  @include responsive(averageTablet) {
    gap: 48px;
  }
}

.heading {
  padding-top: var(--pt-190);
  font-size: 4.6rem;
  font-weight: 600;
  line-height: 5.35rem;
  max-width: 56.5rem;

  @include responsive(tablet) {
    padding-top: var(--pt-136);
    font-size: 4rem;
    line-height: 4.6rem;
  }
}

.vacanciesList {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include responsive(averageTablet) {
    gap: 20px;
  }
}

.empty {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  margin-top: -40px;

  @include responsive(averagePhone) {
    margin-top: 0px;
    height: 10vh;
  }
}

.vacancy {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: var(--bg-lightBlack);
  color: var(--white);
  padding: 36px 36px 36px 48px;
  border-radius: 16px;

  @include responsive(averageTablet) {
    flex-direction: column;
    align-items: start;
    padding: 32px;
    gap: 25px;
  }

  & > .title {
    flex: 0.4;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.263rem;
    max-height: 4.6rem;
    overflow: hidden;
  }

  & > .line {
    width: 1px;
    height: 64px;
    background-color: var(--border-7-light);

    @include responsive(averageTablet) {
      width: 100%;
      height: 1px;
    }
  }

  & > .desc {
    flex: 0.3;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.508rem;
    color: var(--primary-70);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 0px 10px;
    overflow: hidden;

    @include responsive(averageTablet) {
      padding: 0;
    }
  }

  & > .price {
    flex: 0.2;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.017rem;
    text-align: center;

    @include responsive(desktop) {
      font-size: 2rem;
    }
    @include responsive(averageTablet) {
      font-size: 2.4rem;
    }
  }

  & > .btn {
    flex: 0.1;
    background-color: var(--bg-btn);
    font-size: 1.4rem;
    font-weight: 600;
    height: 6.4rem;
    line-height: 1.76rem;
    border: none !important;
    color: var(--white) !important;
    border-radius: 16px !important;
    padding: 2.2rem 3.2rem !important;

    @media (min-width: 2000px) {
      padding: 2.1rem 2.6rem !important;
    }

    @include responsive(averageTablet) {
      width: 100%;
    }

    &:hover {
      background-color: var(--bg-btn-hover);
    }
    &:active {
      background-color: var(--bg-btn-click);
    }
  }
}

.loadBtn {
  height: 6.4rem;
  width: 100%;
  background-color: var(--bg-lightBlack) !important;
  color: var(--primary-70) !important;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 16px;
  margin: 20px 0px;
  border: none;
  overflow: hidden;
  transition: color 0.15s linear;

  &:not(:disabled):hover {
    color: var(--primary) !important;
  }

  @include responsive(tablet) {
    margin-bottom: 8px;
  }
}
